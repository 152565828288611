@import './assets/scss/variables';
@import './assets/scss/mixins';

// html, body{
//   overflow-y: auto;
//   overflow-x: hidden;
//   height: 100%;
// }

#root {
  height: 100vh;
  overflow-y: auto;
}

.container {
  width: 97%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
a,
span,
button,
div {
  font-family: 'Sen', sans-serif !important;
}

button {
  cursor: pointer;
}

.Toastify * {
  z-index: 10000000;
}

.modal-close-icon{
  color: #fff;
  right: 3px;
  top: 3px;
  font-size: 1.9rem !important;
  position: absolute;
  cursor: pointer;
}

.outlined-input{
  & .MuiFormLabel-root,
  & .MuiInputBase-input{
    color: #fff !important;
  }
  & .MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
  }
}

.animated-gift{
  width: 20px;
  height: 20px;
  &.gift-on-avatar{
    width: 30px;
    height: 30px;
  }
  & img{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 992px) {
  .animated-gift{
    &.gift-on-avatar{
      width: 20px;
      height: 20px;
    }
    & img{
      width: 100%;
      height: 100%;
    }
  }
}

.roomBox {
  & .MuiPaper-root{
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: #888;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #2D836D;
    }
  }
}

.main-scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.main-scrollbar::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
.main-scrollbar::-webkit-scrollbar-thumb {
  background: #2D836D;
}

.MuiButton-root {
  background: #2D836D !important;
  color: #fff !important;
}

.green-modal-container {
  .MuiPaper-root {
    background: $background-green !important;
    border: 4px solid $green !important;
    max-width: 802px !important;
    overflow-y: hidden !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-green;
        border-radius: 10px;
      }
    }

    .room-heading {
      color: $white;
      font-weight: $extra-bold;
      font-size: $fs-32;
      line-height: 38px;
      text-align: center;
    }

    .outlined-button {
      border: 2px solid $light-green;

      &.active {
        background: $light-green;
        border-color: $border-green;
      }

      & .title {
        color: $white;
      }
    }

    .room-cont {
      border: 1px solid $light-green;

      .plus-cont {
        .plus {
          border: 0.5px solid $border-green;
          color: $white;
          background: $background-green;
        }

        .line {
          background: $light-green;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .room-item {
        background: $light-green;
        border: 0.5px solid $border-green;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

        .name {
          color: $white;
        }
      }

      .top-item {
        color: $white;
        border-bottom: 1px solid #eee;

        .order {
          color: #eee;
        }

        .top-item-data {
          .count {
            background-color: $white;
            color: black;
          }
        }
      }
    }

    .pagination-cont {
      .pagination {
        width: 100%;

        .Mui-selected {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-green;
        }

        .MuiButtonBase-root {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-green;
          color: $white;
        }
      }
    }

  }

  .modal-bottom-right-img {
    bottom: -67px;
    right: -67px;
    @include size(135px);
  }

  .modal-top-left-img {
    top: -67px;
    left: -67px;
    @include size(135px);
  }
}

.purple-modal-container {
  .MuiPaper-root {
    background: $background-purple !important;
    border: 4px solid $purple !important;
    max-width: 802px !important;
    overflow-y: hidden !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-purple;
        border-radius: 10px;
      }
    }

    .room-heading {
      color: $white;
      font-weight: $extra-bold;
      font-size: $fs-32;
      line-height: 38px;
      text-align: center;
    }

    .outlined-button {
      border: 2px solid $light-purple;

      &.active {
        background: $light-purple;
        border-color: $border-purple;
      }

      & .title {
        color: $white;
      }
    }

    .room-cont {
      border: 1px solid $light-purple;

      .plus-cont {
        .plus {
          border: 0.5px solid $border-purple;
          color: $white;
          background: $background-purple;
        }

        .line {
          background: $light-purple;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .room-item {
        background: $light-purple;
        border: 0.5px solid $border-purple;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

        .name {
          color: $white;
        }
      }

      .top-item {
        color: $white;
        border-bottom: 1px solid #eee;

        .order {
          color: #eee;
        }

        .top-item-data {
          .count {
            background-color: $white;
            color: black;
          }
        }
      }
    }

    .pagination-cont {
      .pagination {
        .Mui-selected {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-purple;
        }

        .MuiButtonBase-root {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-purple;
          color: $white;
        }
      }
    }

  }
}

.yellow-modal-container {
  .MuiPaper-root {
    background: $background-yellow !important;
    border: 4px solid $yellow !important;
    max-width: 802px !important;
    overflow-y: hidden !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-yellow;
        border-radius: 10px;
      }
    }

    .room-heading {
      color: $white;
      font-weight: $extra-bold;
      font-size: $fs-32;
      line-height: 38px;
      text-align: center;
    }

    .outlined-button {
      border: 2px solid $light-yellow;

      & .title {
        color: $white;
      }

      &.active {
        background: $light-yellow;
        border-color: $border-yellow;

        & .title {
          color: $black;
          font-weight: bold;
        }
      }

    }

    .room-cont {
      border: 1px solid $light-yellow;

      .plus-cont {
        .plus {
          border: 0.5px solid $border-yellow;
          color: $white;
          background: $background-yellow;
        }

        .line {
          background: $light-yellow;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .room-item {
        background: $light-yellow;
        border: 0.5px solid $border-yellow;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

        .name {
          color: $black;
        }
      }

      .top-item {
        color: $white;
        border-bottom: 1px solid #eee;

        .order {
          color: #eee;
        }

        .top-item-data {
          .count {
            background-color: $white;
            color: black;
          }
        }
      }
    }

    .pagination-cont {
      .pagination {
        .Mui-selected {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-yellow;
        }

        .MuiButtonBase-root {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $border-yellow;
          color: $white;
        }
      }
    }

  }
}

.black-modal-container {
  .MuiPaper-root {
    background: $background-black !important;
    border: 4px solid $border-black !important;
    max-width: 802px !important;
    overflow-y: hidden !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-black;
        border-radius: 10px;
      }
    }

    .room-heading {
      color: $white;
      font-weight: $extra-bold;
      font-size: $fs-32;
      line-height: 38px;
      text-align: center;
    }

    .outlined-button {
      border: 2px solid $light-black;

      & .title {
        color: $white;
      }

      &.active {
        background: $light-black;
        border-color: $light-black;

        & .title {
          font-weight: bold;
        }
      }

    }

    .room-cont {
      border: 1px solid $light-black;
      //background: black;

      .plus-cont {
        .plus {
          border: 0.5px solid $border-black;
          color: $white;
          background: $background-black;
        }

        .line {
          background: $light-black;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .room-item {
        background: $light-black;
        border: 0.5px solid $light-black;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

        .name {
          color: $white;
        }
      }

      .top-item {
        color: $white;
        border-bottom: 1px solid #eee;

        .order {
          color: #eee;
        }

        .top-item-data {
          .count {
            background-color: $white;
            color: black;
          }
        }
      }
    }

    .pagination-cont {
      .pagination {
        .Mui-selected {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $light-black;
        }

        .MuiButtonBase-root {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid $light-black;
          color: $white;
        }
      }
    }

  }
}

.green {
  .main {
    background-color: $black;
    position: relative;
  }

  .MuiPaper-root {
    background: $background-green !important;
    border: 4px solid $green !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-green;
        border-radius: 10px;
      }
    }
  }

  .room-heading {
    color: $white;
  }

  .bottom-navbar__container {
    background: $background-green;
  }

  .navbar {
    &-body {
      background-color: $background-green;
    }
  }
  .passive-btn {
    background: $green;
    border: 2px solid $border-green;
    color: $white;
  }

  .outlined-button {
    border: 2px solid $light-green !important;

    &.active {
      background: $light-green;
      border-color: $border-green;
    }

    & .title {
      color: $white;
    }
  }

  .game-table-user {
    & span {
      background-color: $light-green;
      color: $white;
    }
  }

  .main-chat__container {
    background: rgba(21, 23, 23, 0.75);

    &::-webkit-scrollbar-thumb {
      background: $light-green;
    }
  }

  .user-message {
    border: 2px solid $border-green;
    background: $green-rgba;
    color: $white;
  }

  .my-message {
    border: 1px solid $border-green;
    background: $green-rgba-dark;
    color: $white;
  }

  .send-message-input__container {
    border: 2px solid $border-green;
    background: $green-rgba-dark;

    .input-message {
      color: $white;
    }

    ::placeholder {
      color: $white;
    }
  }

  .friend-item {
    border-bottom: 2px solid $border-green;

    &:first-child {
      border-top: 2px solid $border-green;
    }

    &-info {
      &-name {
        color: $white;

        & span {
          color: $white;
        }
      }

      &-location {
        color: $white
      }
    }
  }

  .plus-cont {
    .plus {
      border: 0.5px solid $border-green;
      color: $white;
      background: $background-green;
    }

    .line {
      background: $light-green;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .room-item {
    background: $light-green;
    border: 0.5px solid $border-green;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

    .name {
      color: $white;
    }
  }

  .top-item {
    color: $white;
    border-bottom: 1px solid #eee;

    .order {
      color: #eee;
    }

    .top-item-data {
      .count {
        background-color: $white;
        color: black;
      }
    }
  }

  .bottom-navbar-btn,
  .mute-component {
    border: 1px solid $dark-green;
    color: $white;
  }

  .mute-component {
    img {
      color: $white;
    }
  }

  .my-status {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid #092922;

    .clip-path-item {
      background: $white;
    }
  }

  .data-item-edit {

    .edit,
    .item {
      color: $black;
    }
  }

  .dark-button {
    border: 1.5px solid $dark-green;

    & span {
      color: $white;
    }
  }

  .speed-up {
    &-btn {
      background: $greeen-theme-yellow;
      color: $white;
      border: 1px solid $greeen-theme-yellow;
    }

    .speed-up-container {

      .start,
      .end {
        border: 3px solid #f3f3f3;
        color: $white;
      }

      .start {
        background: $greeen-theme-yellow;
      }

      .speed-line {
        background: $greeen-theme-yellow;
        border: 2px solid #f3f3f3;

        .dark-green-section {
          background: #F3F3F3;
        }

        .light-green-section {
          background: $greeen-theme-yellow;
        }
      }
    }
  }

  .active-btn {
    background: #2F947B;
    color: $white;
    box-shadow: 26px 10px 50px rgba(0, 0, 0, 0.25);
  }

  .edit-profile-photo {
    background: $white;
    position: sticky;
    top: 30px;
    .cover-img-container {
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid #2D836D;
      color: $white;
    }

    .info-cont {
      .info {
        .info-name {
          color: $white;

        }

        .online {
          color: #51DCB9;
        }
      }

      .edit-cover-btn {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #2D836D;
        color: $white;
      }
    }

    .edit-title {
      color: $white;
    }
  }

  .chose-favourite {
    border: 2px solid $light-green;
    background: rgba(19, 47, 40, 0.75);
    border: 2.66667px solid $green;
    box-shadow: 17.3333px 6.66667px 33.3333px rgba(0, 0, 0, 0.25);

    &-title {
      color: $white;
    }
  }

  .user-profile-info {
    background: $white;
    border: 2px solid #092922;

    .buttons {

      .message-btn,
      .play-with-her-btn {
        background: rgba(0, 0, 0, 0.5);
        color: $white;
      }
    }

    .profile-info {
      &-status {
        border-bottom: 1px solid #092922;
      }
    }
  }

  .profile-body {
    background: $white;

    .description {
      color: $white;
    }
  }

  .users-cont {

    .date,
    .name {
      color: $white;
    }
  }
}

.purple {
  .main {
    background-color: $black;
  }

  .main-scrollbar::-webkit-scrollbar-thumb {
    background: $background-purple;
  }  

  .MuiPaper-root {
    background: $background-purple !important;
    border: 4px solid $purple !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-purple;
        border-radius: 10px;
      }
    }
  }

  .room-heading {
    color: $white;
  }

  .bottom-navbar__container {
    background: $background-purple;
  }

  & .MuiButtonBase-root{
    background-color: $background-purple !important;
  }

  .navbar {
    &-body {
      background-color: $background-purple;
    }
  }

  .passive-btn {
    background: $purple;
    border: 2px solid $border-purple;
    color: $white;
  }

  .pagination-cont {
    .pagination {
      .Mui-selected {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $border-purple;
      }

      .MuiButtonBase-root {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $border-purple;
        color: $white;
      }
    }
  }

  .outlined-button {
    border: 2px solid $light-purple !important;

    &.active {
      background: $light-purple;
      border-color: $border-purple;
    }

    & .title {
      color: $white;
    }
  }

  .game-block{
    background: #2C3159;
    border-color: #6C42D3;
  }

  .game-table {
    &-user {
      border: 1px solid $border-purple;
      & span {
        background-color: $light-purple;
        color: $white;
      }
    }
  }

  .main-chat__container {
    background: rgba(21, 23, 23, 0.75);

    &::-webkit-scrollbar-thumb {
      background: $light-purple;
    }
  }

  .user-message {
    border: 2px solid $border-purple;
    background: $light-purple;
    color: $white;
  }

  .my-message {
    border: 1px solid $border-purple;
    background: $purple-rgba-dark;
    color: $white;
  }

  .send-message-input__container {
    border: 2px solid $border-purple;
    background: $purple-rgba-dark;

    .input-message {
      color: $white;
    }

    ::placeholder {
      color: $white;
    }
  }

  .friend-item {
    border-bottom: 2px solid $border-purple;

    &:first-child {
      border-top: 2px solid $border-purple;
    }

    &-info {
      &-name {
        color: $white;

        & span {
          color: $white;
        }
      }

      &-location {
        color: $white
      }
    }
  }

  .plus-cont {
    .plus {
      border: 0.5px solid $border-purple;
      color: $white;
      background: $background-purple;
    }

    .line {
      background: $light-purple;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .room-item {
    background: $light-purple;
    border: 0.5px solid $border-purple;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

    .name {
      color: $white;
    }
  }

  .top-item {
    color: $white;
    border-bottom: 1px solid #eee;

    .order {
      color: #eee;
    }

    .top-item-data {
      .count {
        background-color: $white;
        color: black;
      }
    }
  }

  .room-cont {
    border: 1px solid $light-purple;
  }

  .bottom-navbar-btn,
  .mute-component {
    border: 1px solid $border-purple;
    color: $white;
  }

  .mute-component {
    img {
      color: $white;
    }
  }

  .my-status {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid #092922;

    .clip-path-item {
      background: $white;
    }
  }

  .data-item-edit {

    .edit,
    .item {
      color: $black;
    }
  }

  .dark-button {
    border: 1.5px solid $border-purple;

    & span {
      color: $white;
    }
  }

  .speed-up {
    &-btn {
      background: $light-blue;
      color: $white;
      border: 1px solid $light-blue;
    }

    .speed-up-container {

      .start,
      .end {
        border: 3px solid #f3f3f3;
        color: $white;
      }

      .start {
        background: $light-blue;
      }

      .speed-line {
        background: $light-blue;
        border: 2px solid #f3f3f3;

        .dark-green-section {
          background: #F3F3F3;
        }

        .light-green-section {
          background: $light-blue;
        }
      }
    }
  }

  .active-btn {
    background: $light-purple;
    color: $white;
    box-shadow: 26px 10px 50px rgba(0, 0, 0, 0.25);
  }

  .edit-profile-photo {
    background: $white;
    position: sticky;
    top: 30px;
    .cover-img-container {
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid $light-purple;
      color: $white;
    }

    .info-cont {
      .info {
        .info-name {
          color: $white;

        }

        .online {
          color: #51DCB9;
        }
      }

      .edit-cover-btn {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #2D836D;
        color: $white;
      }
    }

    .edit-title {
      color: $white;
    }
  }

  .chose-favourite {
    background: $background-purple;
    border: 2.66667px solid $purple;
    box-shadow: 17.3333px 6.66667px 33.3333px rgba(0, 0, 0, 0.25);

    &-title {
      color: $white;
    }
  }

  .user-profile-info {
    background: $white;
    border: 2px solid #092922;

    .buttons {

      .message-btn,
      .play-with-her-btn {
        background: rgba(0, 0, 0, 0.5);
        color: $white;
      }
    }

    .profile-info {
      &-status {
        border-bottom: 1px solid #092922;
      }
    }
  }

  .profile-body {
    background: $white;

    .description {
      color: $white;
    }
  }

  .users-cont {

    .date,
    .name {
      color: $white;
    }
  }
}

.yellow {
  .main {
    background-color: $black;
  }

  .main-scrollbar::-webkit-scrollbar-thumb {
    background: $light-yellow;
  }  

  .MuiPaper-root {
    background: $background-yellow !important;

    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-yellow;
        border-radius: 10px;
      }
    }
  }

  .MuiButton-root{
    background-color: #F9DA38 !important;
    color: #555 !important;
  }

  .game-block{
    background: #201617;
    border-color: #F9DA38;
  }

  .room-heading {
    color: $white;
  }

  .bottom-navbar__container {
    background: $background-yellow;
  }

  .navbar {
    &-body {
      background-color: $background-yellow;
    }
  }

  .passive-btn {
    background: $yellow;
    border: 2px solid $border-yellow;
    color: $white;
  }

  .pagination-cont {
    .pagination {
      .Mui-selected {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $border-yellow;
      }

      .MuiButtonBase-root {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $border-yellow;
        color: $white;
      }
    }
  }

  .outlined-button {
    border: 2px solid $light-yellow !important;

    & .title {
      color: $white;
    }

    &.active {
      background: $light-yellow;
      border-color: $border-yellow;

      & .title {
        color: $black;
        font-weight: bold;
      }
    }

  }

  .game-table {
    &-user {
      border: 1px solid $border-yellow;

      & span {
        background-color: $light-yellow;
        color: $black;
      }
    }
  }

  .main-chat__container {
    background: rgba(21, 23, 23, 0.75);

    &::-webkit-scrollbar-thumb {
      background: $light-yellow;
    }
  }

  .user-message {
    border: 2px solid $border-yellow;
    background: $light-yellow;
    color: $black;
    font-weight: $bold
  }

  .my-message {
    border: 1px solid $border-yellow;
    background: $light-yellow;
    color: $black;
    font-weight: $bold
  }

  .send-message-input__container {
    border: 2px solid $border-yellow;
    background: $yellow-rgba-dark;

    .input-message {
      color: $white;
    }

    ::placeholder {
      color: $white;
    }
  }

  .friend-item {
    border-bottom: 2px solid $border-yellow;

    &:first-child {
      border-top: 2px solid $border-yellow;
    }

    &-info {
      &-name {
        color: $white;

        & span {
          color: $white;
        }
      }

      &-location {
        color: $white
      }
    }
  }

  .plus-cont {
    .plus {
      border: 0.5px solid $border-yellow;
      color: $white;
      background: $background-yellow;
    }

    .line {
      background: $light-yellow;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .room-item {
    background: $light-yellow;
    border: 0.5px solid $border-yellow;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

    .name {
      color: $black;
    }
  }

  .top-item {
    color: $white;
    border-bottom: 1px solid #eee;

    .order {
      color: #eee;
    }

    .top-item-data {
      .count {
        background-color: $white;
        color: black;
      }
    }
  }

  .room-cont {
    border: 1px solid $light-yellow;
  }

  .bottom-navbar-btn,
  .mute-component {
    border: 1px solid $border-yellow !important;
    color: $white !important;
  }

  .mute-component {
    img {
      color: $white;
    }
  }

  .my-status {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid #092922;

    .clip-path-item {
      background: $white;
    }
  }

  .data-item-edit {

    .edit,
    .item {
      color: $black;
    }
  }

  .dark-button {
    border: 1.5px solid $border-yellow;

    & span {
      color: $white;
    }
  }

  .speed-up {
    &-btn {
      background: $light-red;
      color: $white;
      border: 1px solid $light-red;
    }

    .speed-up-container {

      .start,
      .end {
        border: 3px solid #f3f3f3;
        color: $white;
      }

      .start {
        background: $light-red;
      }

      .speed-line {
        background: $light-red;
        border: 2px solid #f3f3f3;

        .dark-green-section {
          background: #F3F3F3;
        }

        .light-green-section {
          background: $light-red;
        }
      }
    }
  }

  .active-btn {
    background: $light-yellow;
    color: $white;
    box-shadow: 26px 10px 50px rgba(0, 0, 0, 0.25);
  }

  .edit-profile-photo {
    background: $white;
    position: sticky;
    top: 30px;
    .cover-img-container {
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid $light-yellow;
      color: $white;
    }

    .info-cont {
      .info {
        .info-name {
          color: $white;

        }

        .online {
          color: #51DCB9;
        }
      }

      .edit-cover-btn {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #2D836D;
        color: $white;
      }
    }

    .edit-title {
      color: $white;
    }
  }

  .chose-favourite {
    background: $background-yellow;
    border: 2.66667px solid $yellow;
    box-shadow: 17.3333px 6.66667px 33.3333px rgba(0, 0, 0, 0.25);

    &-title {
      color: $white;
    }
  }

  .user-profile-info {
    background: $white;
    border: 2px solid #092922;

    .buttons {

      .message-btn,
      .play-with-her-btn {
        background: rgba(0, 0, 0, 0.5);
        color: $white;
      }
    }

    .profile-info {
      &-status {
        border-bottom: 1px solid #092922;
      }
    }
  }

  .profile-body {
    background: $white;

    .description {
      color: $white;
    }
  }

  .users-cont {

    .date,
    .name {
      color: $white;
    }
  }
}

.black {
  .guests-item-container .guest-personal-info {
    border: 1px solid $room-black-border;

    .guest-name-age {
      background: $border-black;
    }
  }

  .main-scrollbar::-webkit-scrollbar-thumb {
    background: $background-black;
  }  


  .main {
    background-color: $black;
  }

  .MuiPaper-root {
    background: $background-black !important;
    border: 4px solid $light-black !important;

    .MuiDialogContent-root {
      padding-left: 0;
      padding-right: 0;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-black;
        border-radius: 10px;
      }
    }
  }

  .game-block{
    background: #1F1F1F;
    border-color: #535353;
  }

  .room-heading {
    color: $white;
  }

  .bottom-navbar__container {
    background: $background-black;
  }

  .navbar {
    &-body {
      background-color: $background-black;
    }
  }

  .passive-btn {
    background: $theme-black;
    border: 2px solid $light-black;
    color: $white;
  }

  .pagination-cont {
    .pagination {
      .Mui-selected {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $light-black;
      }

      .MuiButtonBase-root {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid $light-black;
        color: $white;
      }
    }
  }

  .outlined-button {
    border: 2px solid $light-black !important;

    & .title {
      color: $white;
    }

    &.active {
      background: $light-black;
      border-color: $light-black;

      & .title {
        font-weight: bold;
      }
    }

  }

  .game-table {
    &-user {
      border: 1px solid $light-black;
      & span {
        background-color: $light-black;
        color: $white;
      }
    }
  }

  .main-chat__container {
    background: rgba(21, 23, 23, 0.75);

    &::-webkit-scrollbar-thumb {
      background: $light-black;
    }
  }

  .user-message {
    border: 2px solid $light-black;
    background: $light-black;
    color: $white;
    font-weight: $bold
  }

  .my-message {
    border: 1px solid $light-black;
    background: $light-black;
    color: $white;
    font-weight: $bold
  }

  .send-message-input__container {
    border: 2px solid $light-black;
    background: $light-black;

    .input-message {
      color: $white;
    }

    ::placeholder {
      color: $white;
    }
  }

  .friend-item {
    border-bottom: 2px solid $light-black;

    &:first-child {
      border-top: 2px solid $light-black;
    }

    &-info {
      &-name {
        color: $white;

        & span {
          color: $white;
        }
      }

      &-location {
        color: $white
      }
    }
  }

  .plus-cont {
    .plus {
      border: 0.5px solid $light-black;
      color: $white;
      background: $background-black;
    }

    .line {
      background: $light-black;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .room-item {
    background: $light-black;
    border: 0.5px solid $light-black;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);

    .name {
      color: $black;
    }
  }

  .top-item {
    color: $white;
    border-bottom: 1px solid #eee;

    .order {
      color: #eee;
    }

    .top-item-data {
      .count {
        background-color: $white;
        color: black;
      }
    }
  }

  .room-cont {
    border: 1px solid $light-black;
  }

  .bottom-navbar-btn,
  .mute-component {
    border: 1px solid $light-black;
    color: $white;
  }

  .mute-component {
    img {
      color: $white;
    }
  }

  .my-status {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid #092922;

    .clip-path-item {
      background: $white;
    }
  }

  .data-item-edit {

    .edit,
    .item {
      color: $black;
    }
  }

  .dark-button {
    border: 1.5px solid $light-black;

    & span {
      color: $white;
    }
  }

  .speed-up {
    &-btn {
      background: $black-theme-green;
      color: $white;
      border: 1px solid $black-theme-green;
    }

    .speed-up-container {

      .start,
      .end {
        border: 3px solid #f3f3f3;
        color: $white;
      }

      .start {
        background: $black-theme-green;
      }

      .speed-line {
        background: $black-theme-green;
        border: 2px solid #f3f3f3;

        .dark-green-section {
          background: #F3F3F3;
        }

        .light-green-section {
          background: $black-theme-green;
        }
      }
    }
  }

  .active-btn {
    background: $light-black;
    color: $white;
    box-shadow: 26px 10px 50px rgba(0, 0, 0, 0.25);
    border: 1px solid $light-black;
  }

  .edit-profile-photo {
    background: $white;
    position: sticky;
    top: 30px;
    .cover-img-container {
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid $light-black;
      color: $white;
    }

    .info-cont {
      .info {
        .info-name {
          color: $white;

        }

        .online {
          color: #51DCB9;
        }
      }

      .edit-cover-btn {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #2D836D;
        color: $white;
      }
    }

    .edit-title {
      color: $white;
    }
  }

  .chose-favourite {
    background: $background-black;
    border: 2.66667px solid $border-black;
    box-shadow: 17.3333px 6.66667px 33.3333px rgba(0, 0, 0, 0.25);

    &-title {
      color: $white;
    }
  }

  .user-profile-info {
    background: $white;
    border: 2px solid #092922;

    .buttons {

      .message-btn,
      .play-with-her-btn {
        background: rgba(0, 0, 0, 0.5);
        color: $white;
      }
    }

    .profile-info {
      &-status {
        border-bottom: 1px solid #092922;
      }
    }
  }

  .profile-body {
    background: $white;

    .description {
      color: $white;
    }
  }

  .users-cont {

    .date,
    .name {
      color: $white;
    }
  }
}

.swiper-scrollbar {
  display: none;
}

.guest-slider-container .swiper-slide-next+div+div+div+div {
  opacity: 0.3 !important;
}

.swiper-slide {
  margin-right: 15px !important;
}

.hide-cover-img {
  display: none !important;
}

.change-theme-dialog .MuiPaper-root {
  background: transparent !important;
}

.change-theme-dialog .MuiDialog-container {
  background: rgba(0, 0, 0, 0.5) !important;
}

.react-jinke-music-player-main{
  &-add-icon{
    position: absolute;
    z-index: 4050;
    bottom: 23px;
    right: 35px;
    display: block;
    width: 30px;
    cursor: pointer;
    fill: #fff;
    transition: all .2s;
  }
  & .react-jinke-music-player-mobile{
    z-index: 4000;
  }
  &.light-theme ~ .react-jinke-music-player-main-add-icon{
    fill: #31c27c;
  }
  & .audio-lists-panel-mobile.show{
    z-index: 4200;
  }
}

@media screen and (max-width: 768px) {
  .react-jinke-music-player-main {
    &-add-icon{
      top: 10px;
      left: 10px;
    }
    & svg{
      font-size: 18px !important;  
    }
  }
  .react-jinke-music-player .music-player-controller{
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 510px) {
  .pagination-cont {
    .pagination {
      .MuiButtonBase-root {
        width: 4vw !important;
        padding: 2px, 2px !important;
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .pagination-cont {
    .pagination {
      .MuiButtonBase-root {
        width: 3vw !important;
        padding: 1px, 1px !important;
        font-size: 12px !important;
      }
    }
  }
}