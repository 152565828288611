.navbar-language {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1000;
  & div {
    background: #b5b5b5;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    & img {
      width: 30px;
      height: 20px;
    }

    &.list {
      z-index: 999;
      display: none;
      & img{
        margin-top: 8px;
      }

      &.active {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .navbar-language {
    & div {
      & img {
        width: 25px !important;
      }
    }
  }
}