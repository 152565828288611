@import "./variables";

@mixin imageSize($width, $height: $width, $size, $position) {
  object-fit: $size;
  object-position: $position;
  width: $width;
  height: $height;
}

@mixin backgroundSize($size, $position, $repeat) {
  background-size: $size;
  background-repeat: $repeat;
  background-position: $position;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin fontStyles($font-weight, $font-size, $line-height) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}