.auth{
  &-header{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #000;
    padding: 5px 0;
    position: relative;
    & span{
      font-size: 24px;
      color: #fff;
      font-weight: 700;
    }
    & .navbar-language{
      position: absolute;
      right: 0;
    }
  }
  &-btn{
    background: #2D836D;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
    padding: 15px 0;
    font-weight: 800;
    color: #F3F4F8;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 420px) {
  .auth-form-header-title{
    font-size: 18px;
  }
  .auth-form-label{
    margin-bottom: 2px !important;
  }
  .interfaceLanguageLable{
    margin-bottom: 2px !important;
  }
}