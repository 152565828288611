/* Colors */

//green
$background-green: #132F28;
$border-green: #236B59;
$light-green: #2D836D;
$dark-green: #236B59;
$border-dark-green: #236B59;
$white: #FFFFFF;
$black: #151717; 
$green: #286052;
$yellow: #CD9322;
$dark-black: #000;
$green-rgba:rgba(35, 107, 89, 0.5);
$green-rgba-dark:rgba(23, 67, 56, 0.5);
$white: #FFFFFF;
$black: #151717;
$green: #286052;
$greeen-theme-yellow: #CD9322;
$sections-green: #6cb16c;

//purple
$background-purple: #2C3159;
$border-purple: #6C43D2;
$light-purple: #794DE2;
$dark-purple: hsl(165, 49%, 18%);
$purple-rgba-dark:#282C50;
$white: #FFFFFF;
$black: #151717; 
$purple: #6C42D3;
$light-blue: #2DAAC8;

//yellow
$background-yellow: #201617;
$border-yellow: #F6D51F;
$light-yellow: #FFE144;
$dark-yellow: hsl(165, 49%, 18%);
$yellow-rgba-dark:#1C1C1C;
$white: #FFFFFF;
$black: #151717; 
$yellow: #F9DA38;
$light-red: #E75A5A;

//black

$background-black: #1F1F1F;
$border-black: #535353;
$light-black: #686868;
$room-black-border:#131313;
$theme-black:#161616;
$white: #FFFFFF;
$black: #151717; 

$black-theme-green: #3EAA69;

// fonts
$fs-36: 36px;
$fs-32: 32px;
$fs-18: 18px;
$fs-16: 16px;
$fs-12: 12px;
$fs-14: 14px;
$fs-8: 8px;

// regular
$regular: 400;
$medium: 500;
$bold: 700;
$extra-bold: 800;




// z-index

$no-action: 2000000;

// Clans

$clans-modal: 14000;
$clans-create: 14500;
$clans-edit: 15000;
$clans-delete: 16000;
$clans-leave: 16000;


// Games

$karaoke-confirm: 10000;
$like-player-animation: 1200;


// Other

$buy-money-modal: 16000;
$messages-dialog: 17000;
$profile-dialog: 13000;
$comment-dialog: 15000;