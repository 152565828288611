.main-loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  & svg{
    width: 500px;
    height: 500px;
  }
  & span{
    font-size: 18px;
    text-align: center;
  }
  & img{
    width: 400px;
    margin-top: 15px;
  }
}