.fbupdate-info{
  &-form{
    display: flex;
    flex-direction: column;
    & .MuiFormLabel-root{
      color: #555 !important;
    }
    & .MuiFormControl-root,
    & .gender-select,
    & .interestedIn-select,
    & .country-select,
    & .interfaceLang-select{
      margin-bottom: 10px;
    }
  }
}